import { FetchPolicy } from '@apollo/client';

// Calix contact info
export const CUSTOMER_OPS_EMAIL = 'calixcustomerops@calix.com';

// URL query params
export const QUOTE_ID_PARAM = 'quoteId';
export const BUY_ID_PARAM = 'buyId';
export const RETURN_URL_PARAM = 'returnUrl';
export const TERM_URL_PARAM = 'term';

// Cookie names
export const LOGGED_IN_JWT_COOKIE = 'LoggedInUserEcommJWT';
export const LOGGED_IN_TOKEN_COOKIE = 'LoggedInUserEcommToken';
export const USER_REQUEST_PATH_COOKIE = 'user_request_path';

//
export const CURRENT_QUOTE_LOCAL_STORAGE = 'current-quote';
export const CURRENT_ACCOUNT_LOCAL_STORAGE = 'current-account';

// Graphql operation names
export const GET_ACCOUNT_OPERATION = 'GetAccount';
export const SEARCH_ACCOUNTS_OPERATION = 'SearchAccounts';
export const SEND_CONTACT_MESSAGE_OPERATION = 'SendContactMessage';
export const SEND_FEEDBACK_OPERATION = 'SendFeedback';
export const GET_ADDRESSES_OPERATION = 'GetAddresses';
export const DELETE_ADDRESS_OPERATION = 'DeleteAddress';
export const CREATE_ADDRESS_OPERATION = 'CreateAddress';
export const FIND_DUPLICATE_PO_NUMBER_OPERATION = 'FindDuplicatedPoNumber';
export const ADD_EXTERNAL_CONFIGURATION_OPERATION = 'AddExternalConfiguration';
export const UPDATE_EXTERNAL_CONFIGURATION_OPERATION = 'UpdateExternalConfiguration';
export const SEARCH_PARTS_BY_TERM_OPERATION = 'SearchPartByTerm';
export const SEARCH_PARTS_OPERATION = 'SearchParts';
export const RECENTLY_ORDERED_PARTS_OPERATION = 'GetListRecentlyOrderedItems';
export const GENERATE_FILE_UPLOAD_PRESIGNED_URL_OPERATION = 'GenerateFileUploadPresignedUrl';

// Bugsnag
export const BUGSNAG_GRAPHQL_IGNORED_OPERATIONS: string[] = [];
export const BUGSNAG_NETWORK_IGNORED_OPERATIONS: string[] = [];
export const BUGSNAG_ERROR_CLASSES = {
  DEFAULT_ERROR: 'Error',
  SPINNER_TIMEOUT: 'Spinner limit reached',
  USER_ERROR: 'User Error',
  USER_UNAUTHORIZED: 'User Unauthorized',
  USER_WITHOUT_ACCESSES: 'User Without Store Access',
  USER_WITHOUT_PROPER_ACCESS: 'User Without Quote Access',
  GRAPHQL_ERROR: 'GraphQL Error',
  GRAPHQL_NETWORK_ERROR: 'GraphQL Network Error',
  MISSING_BROWSER_FEATURE: 'Missing Browser Feature',
  DUPLICATED_ACCOUNT_INFORMATION_ERROR: 'Duplicated Account Information Error',
};

// External URLs
export const RMA_MANAGER_URL = '/rma-manager/cases';
export const RMA_NEW_CASE_DETAILS_URL = '/rma-manager/cases/new/details';
export const RMA_NEW_CASE_SEARCH_URL = '/rma-manager/cases/new/search?newCase=true';
export const ORDER_MANAGER_URL = '/order-manager/orders';

// Labels
export const CONTACT_COPS_LABEL = 'Contact C-Ops';
export const NOT_AVAILABLE_LABEL = 'Not available';
export const UNNAMED_QUOTE_LABEL = 'Unnamed';
export const NO_QUOTE_NAME_LABEL = 'No quote name';
export const UNASSIGNED_GROUP_LABEL = 'Unassigned';

// Default Quote Group IDs
export const UNASSIGNED_GROUP_ID = 1;
export const NULL_GROUP_ID = 0;

// Matches a string that has content AND has characters other than whitespaces
export const NOT_BLANK_REGEX = /^(?!\s+$).+/;

// Feedback text and emojis
export const FEEDBACK_EMOJI_LIST = ['😤', '☹️', '😐', '🙂', '🤩'];
export const FEEDBACK_TEXT_LIST = [
  "I really don't like it",
  "I don't like it",
  "It's fine",
  'I like it',
  'I love it!',
];

// key on session storage
export const FEEDBACK_SESSION_KEY = 'qm-feedback-collected';

//Graphql
export const FETCH_POLICY = {
  CACHE_ONLY: 'cache-only' as FetchPolicy,
  CACHE_FIRST: 'cache-first' as FetchPolicy,
  NETWORK_ONLY: 'network-only' as FetchPolicy,
  NO_CACHE: 'no-cache' as FetchPolicy,
  STANDBY: 'standby' as FetchPolicy,
};

export const QUOTES_PER_REQUEST_LIMIT = 1000;

export const QUOTE_ITEM_EXTENDED_WARRANTY_LINE = 'Extended Warranty';
