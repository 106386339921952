import { Input } from '@Calix-Commerce/design-system/components';
import { getI18NText } from 'I18N';
import dayjs from 'dayjs';
import { useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CurrencyContext } from 'utils/contexts';
import { useFormatPrice } from 'utils/hooks';
import {
  BillingContainer,
  BillingFormInputLabel,
  InputTextWarning,
  Item,
  ItemRow,
  PoNumberPopup,
} from './styledComponents';

type TextInputType = {
  label: string;
  required?: boolean;
  error?: string;
  inputProps?: any;
  remainingCharacter?: number;
  className?: string;
  file?: any;
};

const TextInput = ({
  label,
  required,
  error,
  inputProps = {},
  remainingCharacter,
  className,
  file,
}: TextInputType) => {
  return (
    <ItemRow className={className}>
      {error && error.trim() && <InputTextWarning className="error-text">{error}</InputTextWarning>}
      <Item className="billing-po-number">
        <BillingFormInputLabel label={label} required={required}></BillingFormInputLabel>
        <div className="billing-input-container">
          <Input {...inputProps} error={Boolean(error)}></Input>
          {inputProps && inputProps.type === 'file' && (
            <label htmlFor="tax-exempt-file-upload" className="upload-file-label">
              {(file && file.fileName) || getI18NText('NO_FILE_SELECTED')}
            </label>
          )}
          {remainingCharacter !== undefined && (
            <span className="rem-character-text">{`${remainingCharacter} ${getI18NText('CHARACTERS_REMAINING')}`}</span>
          )}
        </div>
      </Item>
    </ItemRow>
  );
};

export const PurchaseInfoForm = ({
  poNumber,
  onPoNumberChange,
  onValidPoNumberChange,
  onPoFileChange,
  poFile,
  poNumberErrors,
  setPoNumberErrors,
  onConfirmationCallback,
  handleBlurPoNumber,
  errors,
  setErrors,
}) => {
  const { transactionId } = useParams();
  const poNumberRef = useRef<HTMLInputElement>(null);
  const contextCurrency = useContext(CurrencyContext);
  const formatPrice = useFormatPrice();
  const handlePoNumberChange = (newPoNumber) => {
    onPoNumberChange(newPoNumber);
    onValidPoNumberChange(false);
  };

  const handlePoFileChange = (event) => {
    const files = event.currentTarget.files;

    if (!files || !files.length) {
      setErrors({ ...errors, poFile: '' });
      onPoFileChange();

      return;
    }

    const { name, size, type } = files[0];

    if (size === 0) {
      setErrors({
        ...errors,
        poFile: getI18NText('EMPTY_FILE_ERROR'),
      });
      onPoFileChange();

      return;
    }

    if (type !== 'application/pdf') {
      setErrors({
        ...errors,
        poFile: getI18NText('BILLING_INVALID_FILE_TYPE'),
      });
      onPoFileChange();

      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    fileReader.onload = () => {
      onPoFileChange({
        fileData: fileReader.result,
        fileName: name,
        fileSize: size,
        transactionId,
      });
    };

    setErrors({
      ...errors,
      poFile: '',
    });
  };

  const handlePoFileClick = () => {
    setErrors({
      ...errors,
      poFile: '',
    });
    onPoFileChange();
  };

  return (
    <BillingContainer>
      {poNumberErrors.length > 0 && (
        <PoNumberPopup
          isOpen={true}
          position={'center'}
          title={getI18NText('DUPLICATE_PO_NUMBER_HEADER')}
          confirmButtonText={getI18NText('COUNTINUE_DUPLICATE_PO')}
          cancelButtonText={getI18NText('CHANGE_MY_PO')}
          //@ts-ignore
          content={
            <>
              <div className="po-number-info-1">
                <span>{getI18NText('DUPLICATE_PO_NUMBER_INFO_1')}</span>&nbsp;
                <b>{poNumber}</b>&nbsp;
                <span>{getI18NText('DUPLICATE_PO_NUMBER_INFO_2')}</span>
              </div>
              <div className="duplicate-po-number_list">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{getI18NText('SO_NUMBER')}</th>
                      <th>{getI18NText('ORDERED_DATE')}</th>
                      <th>{getI18NText('TOTAL_AMOUNT')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {poNumberErrors.map((poNumberError, poNumberIndex) => (
                      <tr key={poNumberIndex}>
                        <td></td>
                        <td>{poNumberError.salesOrderNumber}</td>
                        <td>{dayjs(poNumberError.fiscalOrderDate).format('MM/DD/YYYY')}</td>
                        <td>{`${formatPrice(poNumberError.orderTotal)} ${contextCurrency}`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="po-number-info-2">
                <span>{getI18NText('DUPLICATE_PO_NUMBER_INFO_3')}</span>
              </div>
            </>
          }
          flag="Warning"
          onConfirmation={() => {
            setPoNumberErrors([]);
            onConfirmationCallback();
          }}
          onCancel={() => {
            setPoNumberErrors([]);
            onValidPoNumberChange(false);
            setErrors({
              ...errors,
              poNumber: '',
            });

            setTimeout(() => {
              poNumberRef.current?.focus();
              poNumberRef.current?.select();
            }, 0);
          }}
        ></PoNumberPopup>
      )}
      <TextInput
        label={getI18NText('PO_NUMBER')}
        required={true}
        error={errors.poNumber}
        remainingCharacter={50 - poNumber.length}
        inputProps={{
          ref: poNumberRef,
          onBlur: (event) => {
            const changedPoNumber = event.target.value && event.target.value.trim();

            if (changedPoNumber) {
              handleBlurPoNumber(changedPoNumber, event.relatedTarget?.id);
            } else {
              setErrors({
                ...errors,
                poNumber: getI18NText('PO_NUMBER_ERROR_MESSAGE'),
              });
            }
          },
          onChange: (event) => {
            setErrors({
              ...errors,
              poNumber: '',
            });

            handlePoNumberChange(event.currentTarget.value);
          },
          maxLength: '50',
          value: poNumber,
        }}
      ></TextInput>
      <TextInput
        className="billing-file-upload"
        label={getI18NText('ATTACHMENTS')}
        error={errors.poFile}
        inputProps={{
          onChange: handlePoFileChange,
          onClick: handlePoFileClick,
          type: 'file',
          accept: '.pdf',
          name: 'tax-exempt-file-upload',
        }}
        file={poFile}
      ></TextInput>
    </BillingContainer>
  );
};
