import { gql } from '@apollo/client';

import { GENERATE_FILE_UPLOAD_PRESIGNED_URL_OPERATION } from 'utils/constants';

export const GENERATE_FILE_UPLOAD_PRESIGNED_URL = gql`
  mutation ${GENERATE_FILE_UPLOAD_PRESIGNED_URL_OPERATION}($input: GenerateFileUploadPresignedUrlInput!) {
    generateFileUploadPresignedUrl(input: $input) {
      presignedUrl
      uploadToken
    }
  }
`;
