import { getI18NText } from 'I18N';
import { FormInputLabel } from 'components/Common';
import { COUNTRY_CODE_LIST } from './CountryCodeList';
import {
  ContactDropdownSecondary,
  ContactInfoFrom,
  ContactTextInput,
  ErrorListContainer,
  InputTextWarning,
  Item,
  PhoneNumberRow,
} from './styledComponents';
import * as shippingSelectors from 'utils/constants/selectors/checkoutShipping';
import { ItemRow } from 'components/Common/styledComponents';
import { ContactInfoFormType, PhoneNumberType } from 'types';
import * as yup from 'yup';

const PhoneNumber = ({
  label,
  required,
  error = {
    countryCode: '',
    areaCode: '',
    number: '',
    extension: '',
  },
  phoneNumber = {
    countryCode: 'country',
    areaCode: '',
    number: '',
    extension: '',
  },
  onPhoneNumberChange,
  onBlurHandler,
}: {
  label: string;
  required?: boolean;
  error?: {
    countryCode: string;
    areaCode: string;
    number: string;
    extension: string;
  };
  phoneNumber: PhoneNumberType;
  onPhoneNumberChange: (phoneNumber: PhoneNumberType) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>, key: string, error: string) => void;
}) => {
  const errorList = Object.keys(error).filter((errorKey) => error[errorKey]);
  const handlePhoneNumberChange = (value, key) => {
    const extractedNumbers = value.match(/\d+/g) || [''];
    const newValue = extractedNumbers.join('');

    onPhoneNumberChange({
      ...phoneNumber,
      [key]: newValue.slice(0, 10),
    });
  };

  const handlePasteEvent = (event, key) => {
    event.preventDefault();
    handlePhoneNumberChange(event.clipboardData.getData('text'), key);
  };

  return (
    <ItemRow className="contact-row">
      <Item>
        <FormInputLabel label={label} required={required}></FormInputLabel>
        <PhoneNumberRow>
          <ContactDropdownSecondary
            className="phone-number-item"
            options={[{ id: 'country', text: 'Country' }, ...COUNTRY_CODE_LIST]}
            selected={phoneNumber.countryCode}
            dropdownProps={{
              'data-testid': shippingSelectors.CONTACT_PHONE_COUNTRY_ID,
            }}
            handleSelectOption={(selected) => {
              onPhoneNumberChange({
                ...phoneNumber,
                countryCode: selected.id,
              });
            }}
          />

          <ContactTextInput
            className="phone-number-item-area-code"
            required={true}
            inputProps={{
              'data-testid': shippingSelectors.CONTACT_PHONE_AREA_ID,
              onBlur: (event) => {
                onBlurHandler(event, 'areaCode', getI18NText('PHONE_AREA_CODE_ERROR_MESSAGE'));
              },
              onChange: (event) => {
                handlePhoneNumberChange(event.currentTarget.value, 'areaCode');
              },
              onPaste: (event) => {
                handlePasteEvent(event, 'areaCode');
              },
              maxLength: '10',
              value: phoneNumber.areaCode,
              placeholder: 'Area',
            }}
          />

          <ContactTextInput
            className="phone-number-item-number"
            required={true}
            inputProps={{
              'data-testid': shippingSelectors.CONTACT_PHONE_NUMBER_ID,

              onBlur: (event) => {
                onBlurHandler(event, 'number', getI18NText('PHONE_NUMBER_ERROR_MESSAGE'));
              },
              onChange: (event) => {
                handlePhoneNumberChange(event.currentTarget.value, 'number');
              },
              onPaste: (event) => {
                handlePasteEvent(event, 'number');
              },
              maxLength: '10',
              value: phoneNumber.number,
              placeholder: 'Number',
              type: 'tel',
            }}
          />

          <ContactTextInput
            className="phone-number-item-extension"
            required={true}
            inputProps={{
              'data-testid': shippingSelectors.CONTACT_PHONE_EXTENSION_ID,
              onChange: (event) => {
                handlePhoneNumberChange(event.currentTarget.value, 'extension');
              },
              onPaste: (event) => {
                handlePasteEvent(event, 'extension');
              },
              maxLength: '10',
              value: phoneNumber.extension,
              placeholder: 'Extension',
            }}
          />
        </PhoneNumberRow>
      </Item>
      <ErrorListContainer direction="column">
        {errorList.map((errorKey, index) => (
          <InputTextWarning key={index} className="error-text">
            {error[errorKey]}
          </InputTextWarning>
        ))}
      </ErrorListContainer>
    </ItemRow>
  );
};

export const ContactInfoForm = ({
  handleContactChange,
  contactInfo = {
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phoneNumber: {
      countryCode: 'country',
      areaCode: '',
      number: '',
      extension: '',
    },
  },
  errors = {
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phoneNumber: {
      countryCode: '',
      areaCode: '',
      number: '',
      extension: '',
    },
  },
  setError,
}: ContactInfoFormType) => {
  const handleSetError = (attribute, event, errorMessage) => {
    setError({
      ...errors,
      [attribute]: event.target.value && event.target.value.trim() ? '' : errorMessage,
    });
  };

  return (
    <ContactInfoFrom>
      <ContactTextInput
        label="First Name"
        required={true}
        error={errors.firstName}
        inputProps={{
          'data-testid': shippingSelectors.CONTACT_FIRST_NAME_ID,
          onChange: (event) => {
            handleSetError('firstName', event, getI18NText('FIRST_NAME_ERROR_MESSAGE'));

            handleContactChange({
              ...contactInfo,
              firstName: event.currentTarget.value,
            });
          },
          maxLength: '150',
          value: contactInfo.firstName,
        }}
      />

      <ContactTextInput
        label="Last Name"
        required={true}
        error={errors.lastName}
        inputProps={{
          'data-testid': shippingSelectors.CONTACT_LAST_NAME_ID,
          onChange: (event) => {
            handleSetError('lastName', event, getI18NText('LAST_NAME_ERROR_MESSAGE'));

            handleContactChange({
              ...contactInfo,
              lastName: event.currentTarget.value,
            });
          },
          maxLength: '150',
          value: contactInfo.lastName,
        }}
      />

      <ContactTextInput
        label="Email"
        required={true}
        error={errors.email}
        inputProps={{
          type: 'email',
          'data-testid': shippingSelectors.CONTACT_EMAIL_ID,
          onChange: (event) => {
            setError({
              ...errors,
              email: getEmailErrorMsg(event.target.value && event.target.value.trim()),
              confirmEmail: contactInfo.confirmEmail
                ? getConfirmEmailError(event.target.value, contactInfo.confirmEmail)
                : '',
            });

            handleContactChange({
              ...contactInfo,
              email: event.currentTarget.value,
            });
          },
          maxLength: '360',
          value: contactInfo.email,
        }}
      />

      <ContactTextInput
        label="Confirm Email"
        required={true}
        error={errors.confirmEmail}
        inputProps={{
          type: 'email',
          'data-testid': shippingSelectors.CONTACT_EMAIL_CONFIRMATION_ID,
          onChange: (event) => {
            setError({
              ...errors,
              confirmEmail: getConfirmEmailError(contactInfo.email, event.target.value),
            });

            handleContactChange({
              ...contactInfo,
              confirmEmail: event.currentTarget.value,
            });
          },
          maxLength: '360',
          value: contactInfo.confirmEmail,
        }}
      />

      <PhoneNumber
        label="Phone Number"
        required={true}
        error={errors.phoneNumber}
        phoneNumber={contactInfo.phoneNumber}
        onPhoneNumberChange={(newPhoneNumber) => {
          const newCountryCode = newPhoneNumber.countryCode;

          setError({
            ...errors,
            phoneNumber: {
              ...errors.phoneNumber,
              countryCode:
                newCountryCode === 'country' ? getI18NText('PHONE_COUNTRY_CODE_ERROR_MESSAGE') : '',
            },
          });

          handleContactChange({
            ...contactInfo,
            phoneNumber: newPhoneNumber,
          });
        }}
        onBlurHandler={(event, key, errorMsg) => {
          setError({
            ...errors,
            phoneNumber: {
              ...errors.phoneNumber,
              [key]: event.target.value && event.target.value.trim() ? '' : errorMsg,
            },
          });
        }}
      />
    </ContactInfoFrom>
  );
};

const getConfirmEmailError = (email, confirmEmail) => {
  let errorMsg = '';

  try {
    yup
      .object()
      .shape({
        confirmEmail: yup
          .string()
          .required(getI18NText('CONFIRM_EMAIL_ERROR_MESSAGE'))
          .email('Please enter a proper email address.'),
      })
      .validateSync({
        confirmEmail,
      });

    //NOTE: If we put this in yup this will be only error we will get
    // as this match will always fail
    if (confirmEmail !== email) {
      errorMsg = "Email doesn't match.";
    }
  } catch (error: unknown) {
    //@ts-ignore
    errorMsg = error.toString().replace('ValidationError: ', '');
  }

  return errorMsg;
};

const getEmailErrorMsg = (email: string): string => {
  let errorMsg = '';

  try {
    yup
      .object()
      .shape({
        email: yup
          .string()
          .required(getI18NText('EMAIL_ERROR_MESSAGE'))
          .email('Please enter a proper email address.'),
      })
      .validateSync({
        email,
      });
  } catch (error: unknown) {
    //@ts-ignore
    errorMsg = error.toString().replace('ValidationError: ', '');
  }

  return errorMsg;
};
