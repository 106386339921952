import { FileType } from 'types/FileType';

export const transformBase64ImageToFormData = async function ({ fileName, fileBase64 }) {
  // Existing files had their prefix removed. We must have it in order to correctly generate the form data
  if (!fileBase64.startsWith('data:application/pdf;base64')) {
    fileBase64 = `data:application/pdf;base64,${fileBase64}`;
  }

  console.time('Time to generate formData');
  const fileContent = await fetch(fileBase64);
  const fileAsBlob = await fileContent.blob();

  const formData = new FormData();
  const file = new File([fileAsBlob], fileName);

  formData.append('image', file);
  console.timeEnd('Time to generate formData');

  return formData;
};

const base64ToArrayBuffer = (base64) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};
const saveByteArray = (reportName, byte, type) => {
  const blob = new Blob([byte], { type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = reportName;
  link.click();
};

export const downloadFile = ({ fileName, fileData, type }) => {
  const appType = type === FileType.XLS ? 'application/xls' : 'application/pdf';
  const sampleArr = base64ToArrayBuffer(fileData);
  saveByteArray(fileName, sampleArr, appType);
};
